import React, { Component } from 'react';
import {Button, ButtonGroup, Container, Form, FormGroup, Input, Label, Table} from 'reactstrap';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

class BidActivityList extends Component {

    emptyItem = {
        start: '',
        end: '',
    };

    constructor(props) {
        super(props);
        this.state = {bids: [], item: this.emptyItem, isLoading: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: false});

        // fetch('/splunk/getBuyerReport')
        //     .then(response => response.json())
        //     .then(data => this.setState({buyers: data, isLoading: false}));
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item}
        item[name] = value;
        this.setState({item});
    }

    handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        fetch(`/splunk/getBidReport/startDate/${item.start}/endDate/${item.end}`)
            .then(response => response.json())
            .then(data => this.setState({bids: data, isLoading: false}));
    }

    render() {
        const {bids, item, isLoading} = this.state;

        if(isLoading) {
            return (
                <div>
                    <AppNavbar/>
                    <p>Loading...</p>
                </div>);
        }

        const bidList = bids.map(bid => {
            return <tr key={bid.repId}>
                <td style={{whiteSpace: 'nowrap'}}>{bid.userName}</td>
                <td>{bid.auctionId}</td>
                <td>{bid.actualLane}</td>
                <td>{bid.virtualLane}</td>
                <td>{bid.type}</td>
                <td>{bid.actionCount}</td>
            </tr>
        });

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <CSVLink data={bids} style={{marginRight: 10}}>Download me</CSVLink>
                        <Button color="success" tag={Link} to="/buyerReport">Refresh List</Button>
                    </div>
                    <h3>New Buyer Client - Bid Activity</h3>
                    <Form inline onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="start">Start Date</Label>
                            <Input type="date" name="start" id="start" value={item.start}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="end">End Date</Label>
                            <Input type="date" name="end" id="end" value={item.end}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary" type="submit">Submit</Button>
                        </FormGroup>
                    </Form>
                    <Table striped hover className="mt-4">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th width="15%">Auction ID</th>
                            <th width="15%">Actual Lane</th>
                            <th width="15%">Virtual Lane</th>
                            <th width="15%">Type</th>
                            <th width="15%">Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {bidList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default BidActivityList;