import React, { Component } from 'react';
import {Button, Container, Form, FormGroup, Input, Label, Table} from 'reactstrap';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import {postRunHistory} from "./api";

class RunHistory extends Component {

    emptyItem = {
        runDate: '',
        auctId: '',
        virtualLane: '',
        runNo: '',
    };

    constructor(props) {
        super(props);
        this.state = {runEvents: [], item: this.emptyItem, isLoading: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: false});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item}
        item[name] = value;
        this.setState({item});
    }

    async executeLambda () {
        try {
            if (this.state.item.auctId && this.state.item.runNo && this.state.item.runDate && this.state.item.virtualLane) {
                const payload = {
                    auctId: this.state.item.auctId,
                    runNo: this.state.item.runNo,
                    runDate: this.state.item.runDate,
                    virtualLane: this.state.item.virtualLane
                };
                const response = await postRunHistory(payload);
                this.setState({runEvents: response, isLoading: false});
            }
        } catch (error) {
            console.error('Error calling the API:', error.message);
            this.setState({ loading: false });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        await this.executeLambda()
    }

    render() {
        const {runEvents, item, isLoading} = this.state;

        if(isLoading) {
            return (
                <div>
                    <AppNavbar/>
                    <p>Loading...</p>
                </div>);
        }

        const eventList = runEvents.map(event => {
            return <tr key={event.timestamp}>
                <td style={{whiteSpace: 'nowrap'}}>{event.timestamp}</td>
                <td>{event.action}</td>
                <td>{event.auctionId}</td>
                <td>{event.saleNumber}</td>
                <td>{event.actualLane}</td>
                <td>{event.virtualLane}</td>
                <td>{event.runNo}</td>
                <td>{event.amount}</td>
                <td>{event.type}</td>
                <td>{event.flag}</td>
                <td>{event.bidderNumber}</td>
                <td>{event.attribute}</td>
                <td>{event.dealerNumber}</td>
                <td>{event.repId}</td>
                <td>{event.laneServer}</td>
            </tr>
        });

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <CSVLink data={runEvents} style={{marginRight: 10}}>Download me</CSVLink>
                        <Button color="success" tag={Link} to="/buyerReport">Refresh List</Button>
                    </div>
                    <h3>Run History</h3>
                    <Form inline onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="runDate">Sale Date</Label>
                            <Input type="date" name="runDate" id="runDate" value={item.runDate}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="auctId">Auction ID</Label>
                            <Input type="text" name="auctId" id="auctId" value={item.auctId}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="virtualLane">Virtual Lane #</Label>
                            <Input type="text" name="virtualLane" id="virtualLane" value={item.virtualLane}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="runNo">Run #</Label>
                            <Input type="text" name="runNo" id="runNo" value={item.runNo}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary" type="submit" onSubmit={this.handleSubmit}>Submit</Button>
                        </FormGroup>
                    </Form>
                    <Table striped hover className="mt-4">
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Action</th>
                            <th>Auction ID</th>
                            <th>Sale #</th>
                            <th>Actual Lane</th>
                            <th>Virtual Lane</th>
                            <th>Run</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Flag</th>
                            <th>Bidder #</th>
                            <th>Attribute</th>
                            <th>Dealer #</th>
                            <th>Rep #</th>
                            <th>Laneserver</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default RunHistory;