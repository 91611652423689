import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RunHistory from "./RunHistory";
import BuyerList from './BuyerList';
import BidActivityList from './BidActivityList';
import getFunctionTest from './getFunctionTest'
import postFunctionTest from './postFunctionTest'
import HealthCheckDash from './HealthCheckDash'
import SimulcastDataDog from "./SimulcastDataDog";

class App extends Component {

  render() {
    return(
        <Router>
          <Switch>
            <Route path='/' exact={true} component={Home}/>
            <Route path='/vehicleRunHistory' exact={true} component={RunHistory}/>
            <Route path='/buyerReport' exact={true} component={BuyerList}/>
            <Route path='/bidReport' exact={true} component={BidActivityList}/>
              <Route path='/healthCheckDash' exact={true} component={HealthCheckDash}/>
              <Route path='/simulcastDataDog' exact={true} component={SimulcastDataDog}/>
          </Switch>
        </Router>
    );
  }
}

export default App;
