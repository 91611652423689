import React, { Component } from 'react';
import AppNavbar from "./AppNavbar";
import './HealthCheckDash.css';
import buildWebserviceURL from './environments/environment'


class HealthCheckDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataImporterEast: '',
            dataImporterWest: '',
            dealerBackend: '',
            vehicleHistoryService: '',
            avmanagerBackend: '',
            kansasAPI: '',
            restfulWebserviceSimulcastClassic: ''
        };

    }

    componentDidMount() {
        fetch(`${buildWebserviceURL()}/sim-support-backend-dataImporterUSEast1-health`)
            .then((response) => response.text())
            .then((dataImporterEast) => this.setState({ dataImporterEast }))
            .catch((error) => console.error('Error fetching data from Importer East:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-dataImporterUSWest2-health`)
            .then((response) => response.text())
            .then((dataImporterWest) => this.setState({ dataImporterWest }))
            .catch((error) => console.error('Error fetching data from Data Importer West:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-dealerBackendUSEast1-health`)
            .then((response) => response.text())
            .then((dealerBackend) => this.setState({ dealerBackend }))
            .catch((error) => console.error('Error fetching data from Dealer Backend:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-vehicleHistoryService-health`)
            .then((response) => response.text())
            .then((vehicleHistoryService) => this.setState({ vehicleHistoryService }))
            .catch((error) => console.error('Error fetching data from Importer East:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-avmanagerBackend-health`)
            .then((response) => response.text())
            .then((avmanagerBackend) => this.setState({ avmanagerBackend }))
            .catch((error) => console.error('Error fetching data from Importer East:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-kansasAPI-health`)
            .then((response) => response.text())
            .then((kansasAPI) => this.setState({ kansasAPI }))
            .catch((error) => console.error('Error fetching data from Importer East:', error));

        fetch(`${buildWebserviceURL()}/sim-support-backend-restfulWebserviceSimulcastClassic-health`)
            .then((response) => response.text())
            .then((restfulWebserviceSimulcastClassic) => this.setState({ restfulWebserviceSimulcastClassic }))
            .catch((error) => console.error('Error fetching data from Importer East:', error));
    }


    render() {
        const { dataImporterEast, dataImporterWest, dealerBackend, vehicleHistoryService, avmanagerBackend, kansasAPI, restfulWebserviceSimulcastClassic } = this.state;

        return (
            <div>
                <AppNavbar/>
                <div>
                    <h1 className="center-status">Simulcast Health Dashboard</h1>
                </div>
                <h4 className="center-status">Internal</h4>
                <div className="container">
                    <div className={`box ${dataImporterEast === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Data Importer US East 1</h5>
                        <p className="center-status">{dataImporterEast === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>

                    <div className={`box ${dataImporterWest === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Data Importer US West 2</h5>
                        <p className="center-status">{dataImporterWest === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                    <div className={`box ${dealerBackend === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Dealer Backend</h5>
                        <p className="center-status">{dealerBackend === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                    <div className={`box ${avmanagerBackend === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">AV Manager Backend</h5>
                        <p className="center-status">{avmanagerBackend === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                    <div className={`box ${kansasAPI === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Kansas API</h5>
                        <p className="center-status">{kansasAPI === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                    <div className={`box ${restfulWebserviceSimulcastClassic === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Restful Webservice Simulcast Classic</h5>
                        <p className="center-status">{restfulWebserviceSimulcastClassic === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                </div>
                <h4 className="center-status">External</h4>
                <div className="container">
                    <div className={`box ${vehicleHistoryService === 'true' ? 'green-box' : 'red-box'}`}>
                        <h5 className="center-status">Vehicle History Service</h5>
                        <p className="center-status">{vehicleHistoryService === 'true' ? 'Status: UP' : 'Status: DOWN'}</p>
                    </div>
                    <div>
                        <a href="https://app.datadoghq.com/account/login?next=%2Fdashboard%2F6gs-fq9-ntr%2Fsonar-play-api-network-metrics%3Ftpl_var_environment%255B0%255D%3Dproductionv10%26from_ts%3D1690555461909%26to_ts%3D1690559061909%26live%3Dtrue" className="button-box">
                            <h5 className="center-status">Sonar API DataDog</h5>
                            <h12 className="center-status">(click)</h12>
                            <p className="center-status"></p>
                        </a>
                    </div>
                    <div>
                        <a href="https://one.newrelic.com/nr1-core/apm/overview/MTE1NzZ8QVBNfEFQUExJQ0FUSU9OfDU5NjY3Nzk2Mg?duration=1800000&filters=%28name%20LIKE%20%27acctmgmt%27%20OR%20id%20%3D%20%27acctmgmt%27%20OR%20domainId%20%3D%20%27acctmgmt%27%29%20AND%20%28name%20LIKE%20%27prod%27%20OR%20id%20%3D%20%27prod%27%20OR%20domainId%20%3D%20%27prod%27%29%20AND%20%28name%20LIKE%20%27acctmgmt%27%20OR%20id%20%3D%20%27acctmgmt%27%20OR%20domainId%20%3D%20%27acctmgmt%27%29%20AND%20%28name%20LIKE%20%27graphql%27%20OR%20id%20%3D%20%27graphql%27%20OR%20domainId%20%3D%20%27graphql%27%29&state=314a9692-610d-86ba-b9dd-640f86fe3b97" className="button-box">
                            <h5 className="center-status">AMS New Relic</h5>
                            <h12 className="center-status">(click)</h12>
                            <p className="center-status"></p>
                        </a>
                    </div>
                </div>
            </div>

        );
    }
}

export default HealthCheckDash;
