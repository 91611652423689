import React, { Component } from 'react';
import './App.css';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

class Home extends Component {
    state={
        isLoading: true,
        isAuthenticated: false,
        user: undefined
    };

    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }
    componentDidMount() {
        if (process.env.REACT_APP_ENV === 'development') {
            this.setState(({isAuthenticated: true}))
        } else {
            this.setState({isAuthenticated: true})
        }
    }

    login() {
        let port = (window.location.port ? ":" + window.location.port : "");
        if (port === ":3000") {
            port = ":8080";
        }
        window.alert("Login not implemented yet");
    }

    logout() {
        // TODO add logout code
    }

    render() {
        const button = this.state.isAuthenticated ?
            <div>
                <Button color="link"><Link to="/vehicleRunHistory">Access Run History</Link></Button>
                <Button color="link"><Link to="/buyerReport">View Buyer Report</Link></Button>
                <Button color="link"><Link to="/bidReport">View Bids Report</Link></Button>
                <Button color="link"><Link to ="/healthCheckDash">Health Check Dashboard</Link></Button>
                <Button color="link"><Link to ="/simulcastDataDog">Simulcast DataDog</Link></Button>
            </div> :
            <Button color="primary" onClick={this.login}>Login</Button>;
        return (
            <div>
                <AppNavbar isAuthenticated={this.state.isAuthenticated}/>
                <Container fluid>
                    <small>Running in <b>{process.env.REACT_APP_ENV}</b> mode.</small>
                    {button}
                </Container>
            </div>
        );
    }
}

export default Home;