import buildWebserviceURL from './environments/environment'

const handleResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
    }
    return response.json();
};

export const getLambdaFunction = async (name) => {
    try {
        const url = `${buildWebserviceURL()}/sim-support-backend-handleRequest?name=${name}`;
        console.log(`get works + ${url}`)
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error('Failed to call the GET Lambda function: ' + error.message);
    }
}

export const postLambdaFunction = async (payload) => {
    try {
        const url = `${buildWebserviceURL()}/sim-support-backend-postRequest`
        console.log(`post works + ${url}`)
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error('Failed to call the POST Lambda function: ' + error.message);
    }
};

export const postRunHistory = async (payload) => {
    try {
        const url = `${buildWebserviceURL()}/sim-support-backend-runHistory`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        return handleResponse(response)
    } catch (error) {
        throw new Error('Failed to call the POST Lambda function: ' + error.message);
    }
}



