import React, { Component } from 'react';
import AppNavbar from './AppNavbar';
import { postLambdaFunction } from './api.js';


class postFunctionTest extends Component {
    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.executeLambda = this.executeLambda.bind(this)
        this.state = {
            responseData: null,
            loading: true,
            firstName: null,
            lastName: null,
            intermediateFirstName: '',
            intermediateLastName: '',
        };
    }

     async executeLambda () {
        try {
            if (this.state.firstName && this.state.lastName) {
                const payload = {firstName: this.state.firstName, lastName: this.state.lastName};
                const response = await postLambdaFunction(payload);
                this.setState({responseData: response, loading: false});
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.error('Error calling the API:', error.message);
            this.setState({ loading: false });
        }
    }
    async componentDidMount() {
        await this.executeLambda();
    }

    handleFirstNameChange = (event) => {
        this.setState({ intermediateFirstName: event.target.value });
    };

    handleLastNameChange = (event) => {
        this.setState({ intermediateLastName: event.target.value });
    };

    async handleButtonClick () {
        this.setState({
            loading: true,
            firstName: this.state.intermediateFirstName,
            lastName: this.state.intermediateLastName,
        },
            this.executeLambda
        );
    };


    render() {
        const { responseData, loading } = this.state;

        return (
            <div>
                <AppNavbar/>
                {loading ? (
                    <p>Loading data...</p>
                ) : responseData ? (
                    <div>
                        <h3>API Response Data:</h3>
                        <pre>{JSON.stringify(responseData, null, 2)}</pre>
                    </div>
                ) : (
                    <div>
                        <p>No data to display.</p>
                        <input
                            type="text"
                            value={this.state.intermediateFirstName}
                            onChange={this.handleFirstNameChange}
                            placeholder="Enter First Name"
                        />
                        <input
                            type="text"
                            value={this.state.intermediateLastName}
                            onChange={this.handleLastNameChange}
                            placeholder="Enter Last Name"
                        />
                        <button onClick={this.handleButtonClick}>Submit</button>
                    </div>
                )}
            </div>
        );
    }
}

export default postFunctionTest;