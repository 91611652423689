function buildWebserviceURL () {
    const BUILD_TYPE = process.env.BUILD_TYPE || 'development';

    const webserviceBaseURLs = {
        development:
            process.env.LOCAL === 'true'
                ? 'TBD'
                : 'https://sim-support-backend-origin.sim.manheim.man-qa1.com',
        int1: 'https://sim-support-backend-origin.sim.manheim.man-qa1.com',
        production: 'https://sim-support-backend-origin.sim.manheim.com',
        stage: 'https://sim-support-backend-origin.simbeta.manheim.com',
        uat: 'https://sim-support-backend-origin.sim.manheim.man-uat.com',
    };
    return webserviceBaseURLs[BUILD_TYPE];
}


export default buildWebserviceURL


