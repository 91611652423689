import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

class AppNavbar extends Component {

    constructor(props) {
        super(props);
        this.state = {isOpen: false, isAuthenticated: false};
        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        if (process.env.REACT_APP_ENV === 'development') {
            this.setState(({isAuthenticated: true}))
        } else {
            this.setState({isAuthenticated: true})
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const navItems = this.state.isAuthenticated ?
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink tag={Link} to="/vehicleRunHistory"> Run History</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/buyerReport"> New Client Purchases</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/bidReport"> New Client Bids</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/healthCheckDash"> Health Check Dashboard</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/simulcastDataDog"> Simulcast DataDog</NavLink>
                </NavItem>
            </Nav>
            :
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink tag={Link} to="/vehicleRunHistory" disabled> Run History</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/buyerReport" disabled> New Client Purchases</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/bidReport" disabled> New Client Bids</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/healthCheckDash"> Health Check Dashboard</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/simulcastDataDog"> Simulcast DataDog</NavLink>
                </NavItem>
            </Nav>;
        return <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/">Sim Dash</NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar>
                {navItems}
            </Collapse>
        </Navbar>;
    }
}
AppNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};
export default AppNavbar;