import React, { Component } from 'react';
import AppNavbar from "./AppNavbar";
import './HealthCheckDash.css'
import './App.css'
import './HealthCheckDash.css'

class SimulcastDataDog extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const { } = this.state;

        return (
            <div>
                <AppNavbar/>
                <h1 className= "center-status">Simulcast Data Dog</h1>
                <div className="iframe-container">
                    <iframe title="Simulcast-Prod-Instance-Status" src="https://p.datadoghq.com/sb/9PSgbF-519b8edcf33bf3fe9242f56ef293f6dd" width="100%" height="800px"></iframe>
                    <iframe title="Simulcast-Blinkie" src="https://p.datadoghq.com/sb/9PSgbF-be8a69798cb9f025f702ff4d007f9c01" width="100%" height="1100px"></iframe>
                </div>

            </div>

        );
    }
}

export default SimulcastDataDog;
