import React, { Component } from 'react';
import AppNavbar from './AppNavbar';
import {getLambdaFunction} from "./api.js";

class getFunctionTest extends Component {
    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.executeLambda = this.executeLambda.bind(this)
        this.state = {
            responseData: null,
            loading: true,
            name: null,
        };
    }

    async executeLambda() {
        try {
            if (this.state.name) {
                const response =  await getLambdaFunction(this.state.name)
                this.setState({ responseData: response, loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.error('Error calling the API:', error.message);
            this.setState({ loading: false });
        }
    }

    async componentDidMount() {
        await this.executeLambda()
    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    };

    async handleButtonClick () {
        try {
            const name = this.state.name.trim();
            if (name === '') {
                alert('Please enter a valid parameter value.');
                return;
            }

            this.setState({ loading: true });

            const response = await getLambdaFunction(name);

            this.setState({ responseData: response, loading: false });
        } catch (error) {
            console.error('Error calling the API:', error.message);
            this.setState({ loading: false });
        }
    };

    render() {
        const { name, responseData, loading } = this.state;

        return (
            <div>
                <AppNavbar/>
                {loading ? (
                    <p>Loading data...</p>
                ) : responseData ? (
                    <div>
                        <h3>API Response Data:</h3>
                        <pre>{JSON.stringify(responseData, null, 2)}</pre>
                    </div>
                ) : (
                    <p>No data to display.</p>
                )}
                <input
                    type="text"
                    value={name}
                    onChange={this.handleNameChange}
                    placeholder="Enter parameter value"
                />
                <button onClick={this.handleButtonClick}>Submit</button>
            </div>
        );
    }
}

export default getFunctionTest;